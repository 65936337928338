@import '../../../../assets/styles/vars';

.tabs {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0.3125rem;

  .title {
    font-weight: 500;
    margin-right: 0.3125rem;
  }
}

.tab {
  font-weight: 500;
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 0.3125rem;
  border: 1px solid #282c34;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.selected {
  background-color: $accent;
  color: #fff;
}
