.production {
  display: flex;
  justify-content: space-around;
  width: 27%;
  background: #F8F8F8;
  padding: 1.5625rem 1.875rem 1.875rem 1.5625rem;
  flex-direction: column;
  border-radius: 2px;
}

.row {
  display: flex;
  margin-bottom: 0.3rem;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }
}

.key {
  min-width: 43%;
  font-size: 0.95rem;
  font-weight: 600;
}

.value {
  font-size: 0.85rem;
}

.large {
  width: 45%;
}
@media (max-width: 1024px) {
  .production{
    width: 38.2%;
    justify-content: inherit;
    padding: 40px 15px 20px 15px;
  }
  .row{
    margin-bottom: 0.2375rem;
  }
  .key{
    font-size:0.8rem
  }
}