@font-face {
  font-family: "Segoe UI";
  src: url("./fonts-files/Segoe\ UI.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Segoe UI Bold";
  src: url("./fonts-files/Segoe\ UI\ Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "AkzidenzGroteskPro";
  src: local("AkzidenzGroteskPro"),
    url("../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf") format("truetype"),
    url("../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AkzidenzGroteskPro";
  src: local("AkzidenzGroteskPro"),
    url("../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf") format("truetype"),
    url("../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url("../../assets/fonts/fonts-files/Roboto.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
