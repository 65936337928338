@media (max-width: 1600px) {
  html {
    font-size: 16px;
  }
}

// @media (max-width: 1550px) {
//   html {
//     font-size: 15.5px
//   }
// }

// @media (max-width: 1500px) {
//   html {
//     font-size: 15px
//   }
// }

// @media (max-width: 1450px) {
//   html {
//     font-size: 14.5px
//   }
// }

// @media (max-width: 1400px) {
//   html {
//     font-size: 14px;
//   }
// }

// @media (max-width: 1350px) {
//   html {
//     font-size: 13.5px
//   }
// }

// @media (max-width: 1300px) {
//   html {
//     font-size: 13px
//   }
// }

// @media (max-width: 1250px) {
//   html {
//     font-size: 12.5px
//   }
// }

// @media (max-width: 1200px) {
//   html {
//     font-size: 12px
//   }
// }

@media (max-width: 1024px) {
  html {
    font-size: 16px
  }
  .wrapper{
    padding: 0 2.5rem 1.125rem;
  }
  .content{
    padding:0;
    justify-content: inherit;
  }
  .controls{
    width: 15%;
    justify-content: flex-start;
    margin-right: 25px;
  }
  .info{
    width:85%;
    display: initial;
  }
  
  
}

@media screen and (max-width: 1420px){
  .results__header-filters {
    width: 55%;
  }
}

@media screen and (max-width: 1120px){
  .control__icon {
    width: 3.125rem;
    height: 3.125rem;
  }

  .control__text {
    font-size: 1rem;
  }

  .indicators__title {
    font-size: 1.5rem;
  }

  .radio__label {
    margin-bottom: 0.25rem;
  }

  .results__header-filters {
    width: 60%;
  }
}

@media screen and (max-height: 900px){
  .results {
    height: 18.75rem;
    min-height: 18.75rem;
  }
}

@media screen and (max-height: 800px){
  .stats {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0.9375rem 1.25rem 1.25rem 0.9375rem;

    &__row {
      width: 47%;
      margin-bottom: 0;

      &_total {
        order: 1;
      }

      &_valid {
        order: 3;
      }

      &_notvalid {
        order: 2;
      }

      &_error {
        order: 4;
      }
    }
  }
}

@media screen and (max-height: 700px){
  .results {
    height: 15rem;
    min-height: 15rem;
  }
}
