@import '../../../../assets/styles/vars.scss';

.mainContainer{
  display: flex;
  flex-direction: column;  
  max-width: 45rem;
  height: calc(100% - 5rem);
  overflow-y:auto;
}

.dataSection {
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 3%;
  width: 40rem;
  padding: 1rem;
  margin: 1rem;
}

.printersSection{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.dataRow{
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 100%;
}

.dataCell{
  margin-left: 1rem;
  
}

.sectionTitle{
  font-size: medium;
}

.printersSectionTitle{
  font-size: small;
  font-weight: 500;
  display: flex;
  margin-bottom: 0.5rem;
}

.alert {
  background: #fff;
  padding: 0.9375rem 1.5625rem 0.9375rem;
  border-radius: 5px;
  // min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 100%;
  width: 50rem;
height: 40rem;
  text-align: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 101;
 
}

.btn {
  height: 3rem;
  border-radius: 7px;
  font-size: 1rem;
  color: #fff;
  padding: 0 0.9375rem;
  transition: all .3s ease;
  background: $green;
  width: 6.25rem;

  &:hover {
    background: darken($green, 5%);
  }
}

.line{
  border-bottom: 0.8px solid black;
}


.nestedLine {
  border-bottom: 0.5px dashed black
}

.dataCellIndented{
  margin-left: 1.5rem;
}