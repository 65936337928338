.settings {
  // position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // z-index: 0;
  // padding: 0;
  height: 100%;
}

.panels {
  display: flex;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  padding: 0;

}

.controls {
  justify-content: flex-start;

}



@media (max-width: 1024px) {
  .controls {
    .control {
      height: 10px;
    }
  }

}