.input {
  width: 47%;
  &W {
    width: 100%;
  }
}

.inputInput {
  width: 70%;
  &W {
    width: 100%;
  }
}
