@import "../../../../assets/styles/vars";
@import "../../../../assets/styles/functions";


@font-face {
  font-family: "Segoe UI";
  src: url("../../../../assets/fonts/fonts-files/Segoe\ UI.ttf");
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

.dataConfirm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@mixin verticalDisplay($alignItems: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: $alignItems;
}

@mixin confirm($width, $height) {
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  border-radius: 5px;
  min-height: 9.375rem;
  width: ($width);
  height: ($height);
  font-family: "Roboto", sans-serif;
}

@mixin text($marginBottom: rem(10)) {
  margin-bottom: $marginBottom;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: rem(18);
  line-height: rem(24);
}

.confirmProduct {
  @include confirm(910, 486);
  @include verticalDisplay(center);
}

.confirmLine {
  @include confirm(758, 257);
  @include verticalDisplay(flex-start);
}



@mixin text($marginBottom: 0.625rem) {
  margin-bottom: $marginBottom;
  font-family: $font;
  font-style: normal;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.confirmProduct {
  @include confirm(56rem, 33rem);
  @include verticalDisplay(center);
}

.confirmProductWpartNum {
  @include confirm(56rem, 36.5rem);
  @include verticalDisplay(center);
}

.confirmLine {
  @include confirm(50rem, 20.5rem);
  @include verticalDisplay(flex-start);
}

.confirm {
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 104;
  padding: 1.25rem 2rem;
  border-radius: 5px;
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50rem;
  min-width: 30rem;
}

.modalContent {
  padding: 0 1.5625rem 0.9375rem rem(40);
  width: 100%;
}

.commonContent {
  @include verticalDisplay();
  margin-top: 1rem;
  margin-top: rem(15);
  width: 100%;
}

.productContent {
  @include verticalDisplay();
}

.childrenContainer {
  display: flex;
  align-self: center;
  justify-content: center;
  width: 14.87rem;
  height: 3.75rem;
  height: auto;
  padding: 0.75rem 0;
  margin: 0.875rem 0;
  border: 1px solid black;
}

.text {
  @include text();
  line-height: 1.75rem;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;
  letter-spacing: 0.75px;
  align-items: center;
  // text-align: center;
}

.productText {
  @include text(0);
  line-height: 1.75rem;
}

.boldText {
  @include text(0);
  font-weight: 600;
  // margin-right: 5px;
}

.modalHeader {
  background-color: #5589ff;
  width: 100%;
  height: rem(50);
  padding: rem(13) rem(40);
  font-style: normal;
  font-weight: 500;
  font-size: rem(20);
  line-height: rem(24);
  color: #ffffff;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  margin: 1rem 0;
}

.btn {
  width: rem(160);
  height: rem(50);
  border-radius: rem(7);
  font-weight: 500;
  font-size: rem(18);
  line-height: rem(20);
  color: #fff;
  padding: rem(14) rem(23) rem(16) rem(23);
  transition: all 0.3s ease;
  border-radius: rem(10);
}

.btnAgree {
  background: #85c925;
  // margin-right: rem(60);

  &:not([disabled]):hover {
    background: darken(#85c925, 5%);
  }
}

.btnDisagree {
  background: #ececec;
  color: #222b45;

  &:not([disabled]):hover {
    background: darken(#ececec, 5%);
  }
}

.calenderIcon {
  display: flex;
  align-self: center;
}

.isPartNumContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
  position: relative;
  justify-content: center;
  flex-direction: row;

  &>div {
    margin: 0;
    flex-direction: row;
  }
}

.partuNumInput {
  font-family: "Segoe UI";
  height: 2rem;
  width: 12rem;
  display: flex;
  align-items: center;
  font-style: normal;
  border: 1px solid #8a8886;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 1.2rem;
  line-height: 1.375rem;
  height: 2.8125rem;
  padding: 0 0.9375rem;
  font-weight: 500;
  justify-content: center;
  margin: 0;

  &.error {
    border-color: $red;
  }
}

.textRow {
  display: flex;
  gap: 0.75rem;
}

.testModeWarnText {
  @include text(0);
  line-height: rem(28);
  padding: 1rem 2rem;
  color: red;
  text-align: justify;
}