@import "../../../../assets/styles/mixins";

.results {
  width: 100%;
  height: auto;
  min-height: 25.625rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.filters {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.radio {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.table {
  width: 100%;
  margin-top: 1.0625rem;
  height: auto;
}

.key {
  display: flex;
  align-items: center;
  padding: 0.6875rem 0 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: $font;
  font-weight: 500;
  cursor: default;
}


.icon {
  width: 5%;
}

.date {
  width: 20%;
}

.scanner {
  width: 15%;
}

.status {
  width: 20%;
}

.code {
  width: 24.8%;
}

.photo {
  width: 6%;
}

.tableHeader {
  background-color: #F8F8F8;
  height: 2.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid darken(#F8F8F8, 5%);
}

.tableContent {
  height: auto;
  // min-height: 78%;
  background-color: #F8F8F8;
}

@media (max-width: 1024px) {
  .table {
    height: 100%;
  }

  .results {
    max-height: 18.5rem;
    min-height: initial;
  }
}


.rowAnim {

  background-color: yellow;

}