@import '../../../assets/styles/vars.scss';

.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.875rem;
}

.signal-more, .signal-duration {
  width: 40%;
  position: relative;
}

.signal-more:after {
  content: '—';
  position: absolute;
  right: -30%;
  top: calc(50% - 1px);
}

.title {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.subtitle {
  color: #605E5C;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 10px;
}

.inputInput {
  border: 1px solid #8A8886;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 1.125rem;
  line-height: 1.375rem;
  height: 2.8125rem;
  padding: 0 0.9375rem;
  font-weight: 500;
  
  &.error {
    border-color: $red;
  }
}





