@import "vars";

%scrollbar::-webkit-scrollbar {
  width: .5%;
  min-width: 0.5rem;
  height: 0.25rem;
}

%scrollbar::-webkit-scrollbar-track {
  background: #EFEFEF;
}

%scrollbar::-webkit-scrollbar-thumb {
  background: rgba($accent, 0.4);
  border-radius: 5px;
}

@mixin radioColor($color) {
  .radioLabel:checked {
    color: $color;
    background-color: $color;
    color: white;

    &:hover {
      background-color: darken($color, 10%);
    }
  }

  .radioInput:after {
    background: #fff;
  }

  .radioInput:checked {
  }

  .radioInput:checked:before {
    border: 2px solid #fff;

  }

  .radioInput:before {
    border: 2px solid $color;
  }
}

@mixin buttonColor($value) {
  color: white;

  @if $value =="all" {
    background-color: $initialCodeColor;

    &:hover {
      background-color: darken($initialCodeColor, 10%);
    }

    @include radioColor($initialCodeColor);
  }

  @else if $value =="valid" {
    background-color: $validCodeColor;
    @include radioColor($validCodeColor);

    &:hover {
      background-color: darken($validCodeColor, 10%);
    }
  }

  @else if $value =="notvalid" {
    background-color: $invalidCodeColor;
    @include radioColor($invalidCodeColor);

    &:hover {
      background-color: darken($invalidCodeColor, 10%);
    }
  }

  @else if $value =="dropout" or $value =="error" {
    background-color: $noReadCodeColor;
    @include radioColor($noReadCodeColor);

    &:hover {
      background-color: darken($noReadCodeColor, 10%);
    }
  }
}