@import "vars";
@import "mixins";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font;
}

html {
  font-size: 16px;
}

.errorMessageText {
  font-size: 12px;
  color: $error;
  font-weight: 400;
  position: absolute;
  bottom: -20px;
}

span {
  display: inline-block;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

textarea,
button,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: $font;
  border: none;
  background: none;
  outline: none;

  &[disabled] {
    cursor: default;
    opacity: 0.7;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
}

button {
  cursor: pointer;
}

input,
button,
textarea,
select,
option {

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    outline: none;
    -webkit-box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
}

.main {
  position: relative;
}

.wrapper {
  padding: 0 2.5rem 1.125rem;
  height: 100%;
  position: relative;
  min-height: calc(100vh - 2.9375rem);
  display: flex;
  background: #fff;
  flex-direction: column;
}

.indicators {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;

  &__title {
    font-size: 1.6875rem;
    line-height: 2.25rem;
    font-weight: 500;
  }

  &__lamps {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__lamp {
    display: flex;
    margin-left: 1.5625rem;
    align-items: center;

    &:first-child {
      margin-right: 1.25rem;
    }

    &-title {
      font-size: 0.875rem;
      line-height: 1.25rem;
      color: rgba(0, 0, 0, 0.4);
    }

    &-signal {
      background: rgba(0, 0, 0, 0.4);
      border-radius: 100%;
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 0.625rem;
      transition: background-color 0.1s ease;

      &.active {
        background-color: #a80000;
      }
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding-top: 1.563rem;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 18%;
  gap: 1rem;
}

.info {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@import "media";