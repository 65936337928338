@keyframes validCode {
  from {
    background-color: rgba(133, 201, 37, 0.21);
  }

  to {
    background-color: #F8F8F8;
  }
}
@keyframes notValidCode {
  from {
    background-color: rgba(164, 38, 44, 0.21);
  }

  to {
    background-color: #FDE7E9;
  }
}

@keyframes boxAnimation {
  0% {
    transform: translateX(100%) scale(-1, 1);
  }

  50% {
    transform: translateX(0%) scale(-1, 1);
  }

  100% {
    transform: translateX(0) scale(-1, 1);
  }
}