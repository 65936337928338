.consignmentControl {
  background: #F8F8F8;
  width: 17%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.controlBtn {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 100%;
  background: linear-gradient(180deg, #FF7C4B 0%, #D83B01 100%);
  margin-bottom: 0.9375rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: '';
    width: 3.25rem;
    height: 3.25rem;
    position: absolute;
    top: 0.3125rem;
    left: 0.3125rem;
    border: 0.3125rem solid #F8F8F8;
    border-radius: 100%;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover {
    .consignmentControl__btn {
      background: #D83B01;
    }
  }
}

.active {
  .controlBtn {
    background: linear-gradient(320.19deg, #6BB700 -5.45%, #B6EB6D 96.21%);
  }

  &:hover {
    .controlBtn {
      background: #6BB700;
    }
  }
}

.icon {
  width: 1.875rem;
  height: auto;
}

.label {
  font-weight: 600;
  font-size: 0.875rem;
  text-align: center;
  color: #323130;
}

@media (max-width: 1024px) {
  .consignmentControl{
    width: 18.2%;
  }
}