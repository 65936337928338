@import "../../assets/styles/vars";

@font-face {
  font-family: 'AkzidenzGroteskPro';
  src: local('AkzidenzGroteskPro'),
       url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.ttf') format('truetype'),
       url('../../assets/fonts_ext/AkzidenzGroteskPro-Ext.woff') format('woff'); 
  font-weight: normal;
  font-style: normal;
}

.overlay {
  position: fixed;
  background: #5589ff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.form {
  // border-radius: 7px;
  // background: #fff;
  // padding: 1.5625rem 1.875rem 1.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  position: relative;
  align-items: center;
  height: auto;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto; 
}

.logoCompanyName {
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
  font-family: "AkzidenzGroteskPro";
  font-style: normal;
  font-weight: normal;
  font-size: 30.0421px;
  line-height: 38px;
  /* identical to box height */

  letter-spacing: 0.145em;
}

.login {
  align-self: center;
  width: 245px;
  height: 64px;
  border-radius: 7px;
  font-weight: 700;
  color: #fff;
  padding: 0 0.9375rem;
  transition: all 0.3s ease;
  background: $buttonGreen;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;

  &:hover {
    background: darken($buttonGreen, 5%);
  }
}

.error {
  color: $red;
}

.controls {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: auto;
  margin-top: 19px;
}

.logo {
  margin-bottom: 67px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 461px;
  height: auto;
}

.pageTitle {
  font-family: "Roboto", sans-serif;
  color: #fdffff;
  margin-bottom: 27px;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 20px;
}

.authInputDiv {
  display: flex;
  flex-direction: column;
  // border: 1px solid white;
  display: flex;
  height: 64px;
  margin-bottom: 0.75rem;
  width: 100%;

  align-items: center;
  justify-content: center;
}

.authInput {
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  height: 2.8125rem;
  font-weight: bold;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: $authInputFill;
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: $authFontColor;
  font-family: "Roboto", sans-serif;

  &.error {
    border-color: $red;
  }
}

.authInput:focus::placeholder {
  color: transparent;
}

.authInput:focus {
  background-color: #fff;
  height: 100%;
  padding: 0;
  margin: 0;
}
