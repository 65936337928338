@import "../../assets/styles/vars";

.control {
  height: 24%;
  background-color: #f8f8f8;
  border: 2px solid transparent;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease;
  align-items: center;
  position: relative;

  &:not([disabled]):hover {
    background-color: darken(#f8f8f8, 5%);
  }
}

.error {
  background: rgba($red, 0.3) !important;
}

.controlContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.controlIcon {
  width: 4.0625rem;
  height: 4.0625rem;
  margin-bottom: 0.625rem;
}

.controlText {
  font-size: 1.125rem;
}

.settings {
  height: 13%;

  .controlContent {
    flex-direction: row;
  }

  .controlIcon {
    width: 2.6875rem;
    height: 2.6875rem;
    margin-bottom: 0;
    margin-right: 30%;
  }
}

.weighingscale,
.printing,
.scanning,
.aggregation,
.back,
.plc,
.help,
.videohelp,
.callhelp {
  height: 5.3125rem;
  margin-bottom: 1.25rem;

  .controlContent {
    flex-direction: row;
  }

  .controlIcon {
    width: 2.6875rem;
    height: 2.6875rem;
    margin-bottom: 0;
    margin-right: 30%;
  }

  &.active {
    background: linear-gradient(139.94deg, #8fc8f4 20.47%, #0078d4 103.16%);
    border: none;
    color: #fff;
  }
}

.active {
  border-color: #0078d4;
}

.run.active {
  border-color: #6bb700;
}

.stop.active {
  border-color: #d83b01;
}

.pause.active {
  border-color: #0078d4;
}

.callhelp {
  border: 1px solid #d83b01;

  .controlIcon {
    margin-right: 1.875rem;
    height: 1.375rem;
    width: 1.375rem;
  }

  .controlText {
    color: #d83b01;
  }
}

.back {
  .controlContent {
    width: 80%;
    justify-content: space-around;
  }

  .controlIcon {
    margin-right: 1.25rem;
  }
}

.saveBtn {
  background: $green;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #fff;
  height: 3rem;
  min-height: 3rem;
  transition: all 0.3s ease;
  border-radius: 5px;

  &:not([disabled]):hover {
    background: darken($green, 5%);
  }

  &.error {
    background: $red;
  }
}

@media (max-width: 1024px) {
  .control {
    height: 146px;
    min-height: 146px;
    margin-bottom: 15px;
  }

  .back,
  .printing,
  .scanning,
  .aggregation,
  .plc,
  .weighingscale {
    height: 60px;
    min-height: 60px;
  }

  .settings {
    height: 10.2%;

    .controlContent {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .controlIcon {
      width: 4.0625rem;
      height: 4.0625rem;
      margin-bottom: 0.625rem;
      margin-right: 0;
    }

    .controlText {
      font-size: 0.875rem;
    }
  }

  .controlText {
    font-size: 0.875rem;
  }
}
