.datePicker{
 // border: 1px solid #8A8886;
  box-sizing: border-box;
 width: 100%;
 font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  height: 100%;
  padding: 0 0.9375rem;
  }

.datePickerWrapper{
  display: flex;
  align-self: center;
  width: 10rem !important;
}

.settings, .help {
  height: 100%;
  justify-content: flex-start;
}

.infoData {
  height: 25vh;
  padding-bottom: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.panels {
  flex-direction: row;
}

@media (max-width: 1024px) {
  .infoData{
    height: 21vh;
    padding-bottom:1rem;
  }
}
