@import '../../assets/styles/vars';

.header {
  background-color: $accent;
  width: 100%;
  height: 2.9375rem;
  display: flex;
  align-items: center;
  padding: 0 1.563rem;
  position: relative;
  justify-content: space-between;
}

.controls {
  display: flex;
  align-items: center;
}

.logoContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
 // width: 11rem;
  flex-shrink: 0;
  // margin-right: 10rem; 
}

.logoText {
  font-family: "AkzidenzGroteskPro";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.5rem;
  letter-spacing: 0.145em;
  color: #ffffff;
}

.logo {
  width: 3.5rem;
  height: 3.5rem;
  align-self: center;
}

.help {
  color: #fff;
  font-size: 1.5rem;
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 100%;
  top: 0.375rem;
  position: absolute;
  right: calc(2.5rem + 2.125rem + 0.625rem);
  border: 1px solid #fff;
}

.login {
  margin-left: auto;
  position: relative;

  &:hover {
    .logout {
      opacity: 1;
    }

    .avatar {
      opacity: 0;
    }
  }
}

.demo {
  display: flex;
  align-items: center;
  margin-right: 1.3rem;

  .button {
    width: 2.5rem;
    height: 1.25rem;
    margin-right: 0.625rem;
  }

  #button-1 .knobs:before {
    width: 0.75rem;
    height: 0.75rem;
    border-radius : 100%;
    top: 0.25rem;
    background-color: gray;

  }
  #button-1 .checkbox:checked + .knobs:before {
    left: 1.4375rem;
  }
}

.demoText {
  font-size: 0.875rem;
  color: #fff;
  margin-left: 0.95rem;
}

.role {
  color: #fff;
  margin-right: 1rem;
  font-size: 0.95rem;
}

.avatar {
  background-color: #fff;
  border-radius: 100%;
  color: $accent;
  height: 2.125rem;
  width: 2.125rem;
  text-align: center;
  line-height: 2rem;
  font-size: 1rem;
  font-weight: 500;
  transition: all .3s;
}

.logout {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 100%;
  color: #000;
  background: #fff;
  z-index: 20;
  display: flex;
  padding: 7px;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all .3s;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: $accent
    }
  }
}

.lineStatus {
  display: flex;
  color: #F8F8F8;
  font-size: 1rem;
  line-height: 1.375rem;
  margin-left: 3rem;
}

.middleContainer{
  display: flex;  
  justify-content: flex-start;
  width:auto;
  align-items: center;
  }

.emptyContainer{
  display:  flex;
  flex-grow: 100;
}

.lineStatusOuterIndicator{
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 100%;
  flex-shrink: 0;
  margin-left: 1.25rem;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.lineStatusInnerIndicator{
  height: 1.45rem;
  width: 1.45rem;
  border-radius: 100%;  
  margin:0; 
}

.lineStatusIndicatatorRun{
  background: $green;
}

.lineStatusIndicatatorStop{
  background: $red;
}

.lineStatusIndicatatorPaused{
  background: $accent;
}