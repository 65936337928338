.instruction {
  &__title {
    font-size: 1.6875rem;
    line-height: 2.25rem;
    font-weight: 500;
    margin-bottom: 0.9375rem;
  }

  &__section {
    &-title {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 500;
      margin-bottom: 0.75rem;
    }

    &-pic {
      width: 90%;
      border-radius: 4px;
      object-fit: contain;
      object-position: left;
      margin-bottom: 1.5625rem;
    }

    &-text {
      font-size: 1rem;
      line-height: 1.375rem;
      width: 90%;
      margin-bottom: 1.5625rem;
    }
  }

  &__video {
    padding-top: 56.25%;
    width: 100%;
    position: relative;
    max-height: 31.6875rem;

    &-video {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
