@import '../../../assets/styles/vars';

.checkbox {
  position: relative;
  margin-right: 0.5rem;
  display: flex;
  margin-bottom: 1.5625rem;
  cursor: pointer;
}

  .disabled {
    cursor: default !important;

    .checkboxLabel {
      color: #9c9c9c;
    }

    .checkboxLabel, .checkboxInput:before, .checkboxInput:after{
      cursor: default;
    }
  }

  .checkboxLabel {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: inline-block;
  }

  .checkboxInput {
    position: relative;
    cursor: pointer;
    margin-right: 0.5rem;

    &:before, &:after {
      content: '';
      display: inline-block;
      border-radius: 100%;
      transition: all .2s ease;
      cursor: pointer;
    }

    &:before {
      background: #fff;
      width: 1.25rem;
      height: 1.25rem;
      border: 1px solid #8A8886;
    }

    &:after {
      width: 0.875rem;
      height: 0.875rem;
      background: $accent;
      position: absolute;
      top: 0.25rem;
      left: 0.25rem;
      opacity: 0;
    }

    &:checked:after {
      opacity: 1;
    }
  }

  .checkboxInput {
    &:before, &:after {
      border-radius: 0;
    }
  }

.checkbox {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 3;
  border: 1px solid transparent;
  border-radius: 0;

  &:not([checked]) + .knobs:before {
    background-color: gray;
  }

  &:checked + .knobs:before {
    background-color: #fff;
  }
}
