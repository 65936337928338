.consignmentPanel {
  display: flex;
  justify-content: space-between;
  width: 53%;
  border-radius: 2px;
 
 }

.consignment {
  background: #F8F8F8;
  width: 100%;
  padding: 1.5625rem 1.875rem 1.875rem 1.5625rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
   position: relative;
}

.title {
  position: absolute;
  top: 1.5625rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

.row {
  display: flex;
  margin-bottom: 0.3rem;
  // margin-top: 0.1rem;
  word-break: break-word;
  &:last-child {
    margin-bottom: 0;
  }
}

.key {
  min-width: 43%;
  font-size: 0.95rem;
  font-weight: 600;
}

.value {
  font-size: 0.85rem;

}

.mainContainer{
  display: flex;
  flex-direction: column;
}

.consignmentTitle{
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.75rem;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
  display: flex;
}


@media (max-width: 1024px) {
  .consignmentPanel{
    width: 50%;
  }
  .consignment{
    justify-content: space-between;
    padding: 1% 7% 1%% 7%;
  }
  .row{
    margin-bottom: 0.2rem;
    display: block;
    span{
      display: inline;
    }
  }

  .consignmentTitle{
    line-height: 1.6rem;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
  }
  
  .key{
    font-size: 0.75rem;
  }
}