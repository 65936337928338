@import '../../../assets/styles/vars';

.toggle {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 0.2rem;

}

.toggleTitle {
  font-weight: 500;
  margin-right: 0.5rem;
  font-size: 1rem;
  line-height: 1rem;

}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button {
  position: relative;
  width: 3.4375rem;
  top: 0.125rem;
  height: 1.625rem;
  overflow: hidden;
}

.checkbox {
  height: 100%;
  width: 100%;
}

.buttonR,
.buttonR .layer {
  border-radius: 100px;
}

.knobs {
  z-index: 3;
}

.layer {
  width: 100%;
  background-color: #fff;
  border: 1px solid gray;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
.button1 .knobs:before {
  content: '';
  position: absolute;
  top: 0.3125rem;
  left: 0.375rem;
  width: 1rem;
  height: 1rem;
  color: #fff;
  font-size: 0.625rem;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  border-radius: 50%;
  background-color: $accent;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

.button1 .checkbox:checked+.knobs:before {
  content: '';
  left: 2.0625rem;
  background-color: #fff;
}

.button1 .checkbox:checked~.layer {
  background-color: $accent;
}

.button1 .knobs,
.button1 .knobs:before,
.button1 .layer {
  transition: 0.3s ease all;
}