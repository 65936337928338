$accent: #5589ff;
$red: #ff5722;
$green: #4caf50;
$gray: #f8f8f8;
$buttonGreen: #85c925;
$authInputFill: #bbd0ff;
$authFontColor: #605e5c;
$error: rgb(164, 38, 44);

$initialCodeColor: #5589ff;
$validCodeColor: #107c10;
$invalidCodeColor: #ee5f2b;
$noReadCodeColor: #d83b01;
$dropOutColor: #d83b01;

$font: "Segoe UI", sans-serif;

$authPageFont: "Roboto";