@import '../../../../assets/styles/vars';

.tabs {
  height: 48px;
  display: flex;
  margin: 30px 0;
}

.tab {
  background-color: $gray;
  color: $accent;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  padding: 0 30px;
  border-radius: 2px;

  &:last-child {
    margin-right: 0;
  }
}

.selected {
  color: $gray;
  background-color: $accent;
}
@media (max-width: 1024px) {
  .infoData{
    margin: 25px 0;
  }
}