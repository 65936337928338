.swVersionContaier {
  display: flex;
  flex-direction: column;
  min-height: 1.75rem;
  margin-left: 0.75rem;
  margin-top: auto;
  gap: 0.2rem;
}

.version {
  width: 100%;
  display: flex;
  align-items: center;
  color: #00000066;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
}

.infoLink {
  display: flex;
  align-items: center;
  color: #0078d4;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
}

.infoLink:hover {
  text-decoration: underline;
}

.modalWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  color: #0078d4;
  font-style: normal;
  font-family: "Segoe UI";
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.modalBody {
  display: flex;
  flex-direction: column;
  margin: 1rem 0.3rem;
}

.modalText {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.infoList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}

.row {
  display: flex;
  gap: 0.3rem;
}
