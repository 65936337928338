@import "../../../../../../assets/styles/vars";

.aggregateBtn {
  width: 17%;
  background-color: $accent;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  &:hover {
    background-color: darken($accent, 5%);
  }

  &:disabled {
    background-color: gray;
  }
}

.scanned {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: $gray;
}

.label {
  font-size: 1.125rem;
  font-weight: 500;
  color: $gray;
}
@media (max-width: 1024px) {
  .aggregateBtn{
    width: 18.2%;
    height: inherit;
  }
}