@import "../../../../assets/styles/vars";

.selectingAlert {
  background: #fff;
  z-index: 101;
  padding: 0.9375rem 1.5625rem 0.9375rem;
  border-radius: 5px;
  min-height: 9.375rem;
  justify-content: space-around;
  max-width: 50rem;
  width: 21.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.btn {
  height: 2.5rem;
  border-radius: 7px;
  font-size: 1rem;
  color: #fff;
  padding: 0 0.9375rem;
  transition: all 0.3s ease;
  background-color: $red;
  width: 9.375rem;
}

.items {
  display: flex;
  flex-direction: column;
  margin: 1.875rem 0 1.25rem;
  width: 100%;
  max-height: 25rem;
  overflow: auto;
}

.item {
  border: 1px solid #9c9c9c;
  width: 100%;
  border-radius: 5px;
  padding: 0.625rem 0.4375rem;
  margin-bottom: 0.625rem;
  transition: border-color 0.3s;

  &:not([disabled]):hover {
    border-color: #000000;
  }
}
