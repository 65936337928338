.selectLine {
    // transform: translateX(-50%);
  display: flex;
  align-items: center;
  border: 1px solid #FFFFFF;
  border-radius: 2rem;
  padding: 0.313rem 0.938rem 0.313rem 1.563rem;  
}

.title {
  color: #F8F8F8;
  font-size: 1rem;
  line-height: 1.375rem;
}

.arrowDown {
  margin-left: 1.3rem;
}

