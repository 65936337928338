.scanner {
  background: #FFFFFF;
  border-radius: 2px;
  padding: 1.0625rem 1.0625rem 2.1875rem 1.5625rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.scannerControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;


  input[type='radio'] {
    margin-bottom: 0;
  }
}

.scannerInputs {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .name {
    width: 35%;
  }

  .ip {
    width: 30%;
  }

  .port {
    width: 20%;
  }

  .stage {
    width: 15%;
  }
}

.inputInput {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 400;
}

.deleteBtn {
  height: 2.8125rem;
  width: 2.8125rem;
  cursor: pointer;
  margin-left: 0.5rem;
}

.radioButton {
  align-self: center;
  margin-bottom: 0;
}

.addScanner {
  border: 2px dashed rgba(#8A8886, 0.2);
  box-sizing: border-box;
  border-radius: 2px;
  //margin-left: -1.375rem;
  height: 5.625rem;
  transition: all .3s ease;
  //margin-top: 1.25rem;
  margin-bottom: 15px;
  min-height: 5.625rem;

  &:hover {
    border: 2px dashed rgba(#8A8886, 0.5);
  }

  .title {
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 500;
  }
}

.toggle-button-cover {
  position: relative;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .scanner {
    padding: 20px;
    position: relative;
  }

  .scannerControls {
    flex-direction: column;
    align-items: initial;
    margin-top: 0;

  }

  .deleteBtn {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .checkbox {
    // margin-bottom: 10px;
  }

}