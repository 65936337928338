.select {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
}

.title {
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
}

.container {
  position: relative;
  height: 2.8125rem;
  max-height: 5rem;
}

.fancySelect {
  background-color: transparent;
  // border: 1px solid #8A8886;
  position: relative;
  border-radius: 2px;
  font-size: 1.125rem;
  line-height: 1.375rem;
  height: 100%;
  width: 100%;
  padding: 0 0.9375rem;
  font-weight: 500;
  outline: none;
}

.selectInput {
  position: absolute;
  background-color: transparent;
  border: 1px solid #8a8886;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 1.125rem;
  line-height: 1.375rem;
  height: 100%;
  width: 100%;
  padding: 0 0.9375rem;
  font-weight: 500;
  outline: none;
}
