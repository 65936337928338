.visualization {
  display: flex;
  justify-content: space-between;
}

@import '../../../../assets/styles/animations.scss';

:root {
  --boxWidth: 0px;
}


.progressContainer {
  position: relative;
  width: 100%;
}

.emptyContainer {
  height: (1rem + 0.9375rem) * 3;
  background-color: #F8F8F8;
  width: 81%;

  .progressWrapper {
    display: none;
  }
}



.pause {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(139.94deg, #8FC8F4 20.47%, #0078D4 103.16%);
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  color: #fff;
  z-index: 3;
  width: 100%;
  height: 100%;
}


@media (max-width: 1024px) {
  .emptyContainer{
    width: 79.1%;
  }
}