@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins";

.radio {
  position: relative;
  margin-bottom: 1.5625rem;
  cursor: pointer;
  width: 100%;
  height: 40px;
}

.radioLabel {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
}

.toogleButton {
  font-size: 14px;
  display: flex;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  background-color: rgba(237, 237, 237, 0.8);

  &:hover {
    background-color: rgba(229, 239, 255, 0.8);
  }
}

.all {
  @include buttonColor("all");
}

.valid {
  @include buttonColor("valid");
}

.notvalid {
  @include buttonColor("notvalid");
}

.dropout {
  @include buttonColor("dropout");
}

.error {
  @include buttonColor("error");
}

.allPassive {
  .radioInput:before {
    border: 2px solid $initialCodeColor;
  }
}

.validPassive {
  .radioInput:before {
    border: 2px solid $validCodeColor;
  }
}

.notvalidPassive {
  .radioInput:before {
    border: 2px solid $invalidCodeColor;
  }
}

.dropoutPassive {
  .radioInput:before {
    border: 2px solid $dropOutColor;
  }
}

.errorPassive {
  .radioInput:before {
    border: 2px solid $error;
  }
}

.disabled {
  cursor: default !important;

  .radioLabel {
    color: #9c9c9c;
  }

  .radioLabel,
  .radioInput:before,
  .radioInput:after {
    cursor: default;
  }
}

.radioLabel {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.radioInput {
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    border-radius: 100%;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  &:before {
    width: 17px;
    height: 17px;
  }

  &:after {
    width: 12.7px;
    height: 12.7px;
    position: absolute;
    opacity: 0;
  }

  &:checked:after {
    opacity: 1;
  }
}

.radioInput:disabled {
  cursor: default;
}