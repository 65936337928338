@import '../../../../../../assets/styles/animations';

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F8F8F8;
  padding: 0.375rem 0;
  border-bottom: 1px solid darken(#F8F8F8, 5%);
  height: 40px;


}

.rowAnimated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.375rem 0;
  border-bottom: 1px solid darken(#F8F8F8, 5%);
  height: 40px;

  &.valid {
    animation: validCode 0.4s ease forwards;
  }

  &.error {
    animation: notValidCode 0.4s ease forwards;
  }
}

.valid {
  background-color: #F8F8F8;
}

.error {
  background-color: #FDE7E9;
}

.icon {
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 2.5rem;
  }
}

.value {
  font-size: 0.8125rem;
  line-height: 1rem;
  color: #605E5C;
}

.date {
  width: 20%;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.scanner {
  width: 15%;
}

.status {
  width: 20%;
}

.code {
  width: 25%;
  cursor: pointer;
  height: auto;
  overflow: hidden;
  padding: 0.625rem 0;
  border-radius: 2px;
  position: relative;
  font-weight: 700;

}

.photo {
  display: flex;
  align-items: center;
  width: 5%;
}

.newAggregate {
  background-color: #ECECEC;
  padding-left: calc(23px + 2.5%);
  font-weight: bold;
  font-size: 0.875rem;
}
