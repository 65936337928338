.slider {
  width: 100%;
  height: 70vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;

  & * {
    outline: none;
  }
}

.count {
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 1.5rem;
  position: absolute;
  top: -3.125rem;
  left: 0;
}

.slick-slide > div {
  outline: none;
  height: 70vh;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.pic {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
