.loader {
  background: #fff;
  position: fixed;
  opacity: 0.9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.mini {
  position: absolute;
}
