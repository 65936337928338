@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins";

.panel {
  width: 48%;
  height: 100%;
  max-height: 94vh;
  overflow: auto;
  background-color: #f8f8f8;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: relative;
  @extend %scrollbar;
  padding: 1.5rem 0.75rem 2.4375rem 3.1875rem;
}

//&_instruction, &_videohelp {
//  width: 100%;
//}

.panelRadio {
  margin-top: 1.875rem;
}

.panelSubtitle {
  margin-bottom: 1.5rem;
}

//&_instruction {
//  padding: 1.5rem 15% 2.4375rem 2.1875rem;
//}

.panelTabs {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-right: 0.3125rem;
}

.panelTabTitle {
  font-weight: 500;
  margin-right: 0.3125rem;
}

.panelTab {
  font-weight: 500;
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 0.3125rem;
  border: 1px solid #282c34;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.selectedPanelTab {
  background-color: $accent;
  color: #fff;
}

.title {
  font-size: 1.6875rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-bottom: 3.125rem;
}

.range {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.subtitle {
  margin-bottom: 1.125rem;
  width: 100%;
  font-weight: 500;
}

.content {
  margin-top: 30px;

  & > h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  & > ul {
    margin-top: 5px;
    margin-bottom: 5px;
    list-style: disc;
    padding-left: 20px;
  }
}

@media (max-width: 1024px) {
  .panel {
    padding: 20px;
    width: 58%;
  }
}
