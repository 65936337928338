.input {
  width: 48%;
}

.baudrateInput {
  width: 55%;
}

.inputInput {
  width: 90%;
}

.radioContainer {
  width: 18.75rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .stopBits {
    width: 15.625rem;
  }

  .subtitle {
    margin-bottom: 10px;
    width: 100%;
  }
}

.toggle {
  width: 38%;
}

