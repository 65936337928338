@import "../../../assets/styles/vars";
@import "../../../assets/styles/mixins";

.radio {
  position: relative;
  // margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5625rem;
  cursor: pointer;
}

.disabled {
  cursor: default !important;

  .radioLabel {
    color: #9c9c9c;
  }

  .radioLabel,
  .radioInput:before,
  .radioInput:after {
    cursor: default;
  }
}

.radioLabel {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.radioInput {
  position: relative;
  cursor: pointer;
  margin-right: 0.5rem;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    border-radius: 100%;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  &:before {
    background: #fff;
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #8a8886;
  }

  &:after {
    width: 0.875rem;
    height: 0.875rem;
    background: $accent;
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
  }

  &:checked:after {
    opacity: 1;
  }
}

.radioInput:disabled {
  cursor: default;
}

.initial {
  @include radioColor($initialCodeColor);
}

.valid {
  @include radioColor($validCodeColor);
}

.invalid {
  @include radioColor($invalidCodeColor);
}

.noread {
  @include radioColor($noReadCodeColor);
}

.dropout {
  @include radioColor($dropOutColor);
}
