.progress {
  background-color: #F8F8F8;
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0.625rem;
  border-radius: 2px;
  position: relative;
  transform: scale(-1, 1);
}
@import '../../../../../../assets/styles/animations.scss';

.progressWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.box {
  animation: boxAnimation 1s ease forwards;
  height: 4.25rem;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  align-items: center;
  position: relative;
  max-height: (1rem + 0.9375rem) * 3;
  writing-mode: vertical-lr;
  display: inline-flex;
  flex-wrap: wrap;
  transform: scaleX(-1);

  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #EDEBE9;
  }
}


.place {
  height: 1rem;
  width: 1rem;
  background-color: #EDEBE9;
  border-radius: 1px;
  margin: 0.1875rem;
  writing-mode: horizontal-tb;
}

.filled {
  background-color: #85C925;
}
