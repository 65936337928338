@import '../../../../assets/styles/vars';

.alert {
  background: #fff;
  padding: 0.9375rem 1.5625rem 0.9375rem;
  border-radius: 5px;
  min-height: 9.375rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 50rem;
  width: 25rem;
  text-align: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 101;
}

.text {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.controls {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn {
  height: 2.5rem;
  border-radius: 7px;
  font-size: 1rem;
  color: #fff;
  padding: 0 0.9375rem;
  transition: all .3s ease;
  background: $green;
  width: 6.25rem;

  &:hover {
    background: darken($green, 5%);
  }
}
