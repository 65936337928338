.autoUpdateButton {
  display: flex;
  height: 1.875rem;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
  margin-left: 0.5rem;
}

.autoUpdateButtonText {
  padding: 0px 1rem;
  border-radius: 1rem;
  background: var(--Red, #ee5f2b);

  color: #fff;
  // leading-trim: both;
  // text-edge: cap;
  // font-family: Segoe UI;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  /* 257.143% */
  position: relative;
}

@media (max-width: 1260px) {
  .autoUpdateButtonText {
    display: none;
  }
}

.autoUpdateButtonTextAdaptive {
  display: none;
}

@media (max-width: 1260px) {
  .autoUpdateButtonTextAdaptive {
    display: block;
    color: #fff;
    font-size: 1.5rem;
    padding: 4px 16px;
    border-radius: 16px;
    background: var(--Red, #ee5f2b);
    position: relative;
    z-index: 1;

  }
}

.emptyDiv {
  height: 4rem;
}

.btn {
  align-self: center;
  width: 7rem;
  max-height: 2.5rem;
  border-radius: 0.3rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease;
  background: #ECECEC;
  color: #222B45;
}

.btn:hover {
  background: darken(#ECECEC, 5%);
}

.mainCountDownWrapper {
  min-height: 4rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

}